export const defaultCountry = 'GB'
export const reviewsPerPage = 5
export const limitedStockThreshold = 5
export const categoryLoadMoreNum = 12
export const rememberMeLength = 180
export const couponMaxAge = 31536000
export const orderIdCookieMaxAge = 31536000
export const termsDocumentId = 8
export const privacyDocumentId = 9
export const postageDocumentId = 10
export const bestSellersId = 317
export const accessoriesId = 7
export const legacyCookieMaxAge = 31536000
export const defaultParams = { _g: 'rest-api' }
export const registerSteps = ['/register', '/delivery', '/payment']
export const couponErrorMessages = {
  'O-100': 'couponErrorMessages.notFound',
  'O-101': 'couponErrorMessages.maxUsage',
  'O-102': 'couponErrorMessages.expired',
  'O-103': 'couponErrorMessages.previousOrder',
  'O-104': 'couponErrorMessages.certainEmail',
  'O-105': 'couponErrorMessages.certainOrder',
  'O-106': 'couponErrorMessages.specificItems',
  'O-107': 'couponErrorMessages.orderAmount',
  'O-108': 'couponErrorMessages.saleItems',
}
export const footerMenuItems = [
  {
    title: 'footer.help.title',
    icon: '/svg/help-icon.svg',
    items: [
      { title: 'footer.help.items.contact', link: '/document/2' },
      { title: 'footer.help.items.helpCentre', externalLink: 'https://www.lenstore.help/en-gb' },
      { title: 'footer.help.items.delivery', link: '/document/10' },
      { title: 'footer.help.items.refunds', link: '/document/11' },
      { title: 'footer.help.items.refer', link: '/document/126' },
      { title: 'footer.help.items.studentDiscount', link: '/document/106', enableSplit: true },
      { title: 'footer.help.items.keyWorkerDiscount', link: '/document/160' },
      { title: 'footer.help.items.acuvueBrand', link: '/acuvue-brand' },
    ],
  },
  {
    title: 'footer.about.title',
    icon: '/svg/info-icon.svg',
    items: [
      { title: 'footer.about.items.about', link: '/document/77' },
      { title: 'footer.about.items.services', link: '/document/1' },
      { title: 'footer.about.items.terms', link: '/document/8' },
      { title: 'footer.about.items.privacy', link: '/document/9' },
      { title: 'footer.about.items.cookies', extraClasses: 'optanon-show-settings', noLink: true },
      { title: 'footer.about.items.tax', link: '/document/115' },
      { title: 'footer.about.items.affiliates', link: '/document/42' },
      { title: 'footer.about.items.sitemap', link: '/document/60' },
    ],
  },
]

export const footerMenuItemsES = [
  {
    title: 'footer.help.title',
    icon: '/svg/help-icon.svg',
    items: [
      { title: 'footer.help.items.contact', link: '/document/2' },
      { title: 'footer.help.items.helpCentre', externalLink: 'https://www.lenstore.help/es-es' },
      { title: 'footer.help.items.delivery', link: '/document/10' },
      { title: 'footer.help.items.refunds', link: '/document/11' },
      { title: 'footer.help.items.studentDiscount', link: '/document/106', enableSplit: true },
    ],
  },
  {
    title: 'footer.about.title',
    icon: '/svg/info-icon.svg',
    items: [
      { title: 'footer.about.items.services', link: '/document/1' },
      { title: 'footer.about.items.terms', link: '/document/8' },
      { title: 'footer.about.items.privacy', link: '/document/9' },
      { title: 'footer.about.items.cookies', extraClasses: 'optanon-show-settings', noLink: true },
      { title: 'footer.about.items.tax', link: '/document/115' },
      { title: 'footer.about.items.sitemap', link: '/document/60' },
    ],
  },
]
export const footerMenuItemsIT = [
  {
    title: 'footer.help.title',
    icon: '/svg/help-icon.svg',
    items: [
      { title: 'footer.help.items.contact', link: '/document/2' },
      { title: 'footer.help.items.helpCentre', externalLink: 'https://www.lenstore.help/it-it' },
      { title: 'footer.help.items.delivery', link: '/document/10' },
      { title: 'footer.help.items.refunds', link: '/document/11' },
      { title: 'footer.help.items.fiscalDetractionITOnly', link: '/document/129' },
      { title: 'footer.help.items.studentDiscount', link: '/document/106', enableSplit: true },
    ],
  },
  {
    title: 'footer.about.title',
    icon: '/svg/info-icon.svg',
    items: [
      { title: 'footer.about.items.services', link: '/document/1' },
      { title: 'footer.about.items.terms', link: '/document/8' },
      { title: 'footer.about.items.privacy', link: '/document/9' },
      { title: 'footer.about.items.cookies', extraClasses: 'optanon-show-settings', noLink: true },
      { title: 'footer.about.items.tax', link: '/document/115' },
      { title: 'footer.about.items.sitemap', link: '/document/60' },
    ],
  },
]
export const footerMenuItemsFR = [
  {
    title: 'footer.help.title',
    icon: '/svg/help-icon.svg',
    items: [
      { title: 'footer.help.items.contact', link: '/document/2' },
      { title: 'footer.help.items.helpCentre', externalLink: 'https://www.lenstore.help/fr-fr' },
      { title: 'footer.help.items.delivery', link: '/document/10' },
      { title: 'footer.help.items.refunds', link: '/document/11' },
      { title: 'footer.help.items.reimbursementFROnly', link: '/document/11' },
      { title: 'footer.help.items.studentDiscount', link: '/document/106', enableSplit: true },
    ],
  },
  {
    title: 'footer.about.title',
    icon: '/svg/info-icon.svg',
    items: [
      { title: 'footer.about.items.about', link: '/document/77' },
      { title: 'footer.about.items.services', link: '/document/1' },
      { title: 'footer.about.items.terms', link: '/document/8' },
      { title: 'footer.about.items.privacy', link: '/document/9' },
      { title: 'footer.about.items.cookies', extraClasses: 'optanon-show-settings', noLink: true },
      { title: 'footer.about.items.tax', link: '/document/115' },
      { title: 'footer.about.items.sitemap', link: '/document/60' },
    ],
  },
]
export const footerMenuItemsDE = [
  {
    title: 'footer.help.title',
    icon: '/svg/help-icon.svg',
    items: [
      { title: 'footer.help.items.contact', link: '/document/2' },
      { title: 'footer.help.items.helpCentre', externalLink: 'https://www.lenstore.help/de-de' },
      { title: 'footer.help.items.delivery', link: '/document/10' },
      { title: 'footer.help.items.refer', link: '/document/126' },
      { title: 'footer.help.items.rightOfWithdrawal', link: '/document/11' },
      { title: 'footer.help.items.studentDiscount', link: '/document/106', enableSplit: true },
    ],
  },
  {
    title: 'footer.about.title',
    icon: '/svg/info-icon.svg',
    items: [
      { title: 'footer.about.items.about', link: '/document/77' },
      { title: 'footer.about.items.services', link: '/document/1' },
      { title: 'footer.about.items.terms', link: '/document/8' },
      { title: 'footer.about.items.privacy', link: '/document/9' },
      { title: 'footer.about.items.cookies', extraClasses: 'optanon-show-settings', noLink: true },
      { title: 'footer.about.items.tax', link: '/document/115' },
      { title: 'footer.about.items.affiliates', link: '/document/42' },
      { title: 'footer.help.items.imprint', link: '/document/108' },
      { title: 'footer.about.items.sitemap', link: '/document/60' },
    ],
  },
]
export const splitTreatments = {
  showKlarnaSplit: {
    treatmentName: 'klarna_show_on_checkout',
    show: 'show',
  },
  autoReorderReauthenticateCards: {
    treatmentName: 'auto_reorder_reauthenticate_cards',
    show: 'show',
  },
  myAccountAutoReorderEnabled: {
    treatmentName: 'my_account_auto_reorder_spa',
    show: 'enabled',
  },
  collapsedDeliveryAccordionReorder: {
    treatmentName: 'review_and_pay_delivery_accordion_reorder',
    show: 'collapsed',
  },
  collapsedDeliveryAccordionCheckout: {
    treatmentName: 'review_and_pay_delivery_accordion_checkout',
    show: 'collapsed',
  },
  collapsedBasketAccordionReorder: {
    treatmentName: 'review_and_pay_basket_accordion_reorder',
    show: 'collapsed',
  },
  collapsedBasketAccordionCheckout: {
    treatmentName: 'review_and_pay_basket_accordion_checkout',
    show: 'collapsed',
  },
  autoReorderSetup: {
    treatmentName: 'auto_reorder_setup_show_on_checkout',
    show: 'show',
  },
  uspBannerVisible: {
    treatmentName: 'usp_banner_show_in_header',
    show: 'show',
  },
  paymentApplePay: {
    treatmentName: 'payment_show_apple_pay',
    show: 'show',
  },
  paymentGooglePay: {
    treatmentName: 'payment_show_google_pay',
    show: 'show',
    testing: 'testing',
    hide: 'hide',
  },
  paymentPaypalExpress: {
    treatmentName: 'payment_show_paypal_express',
    show: 'show',
    fallback: 'show',
  },
  paymentBraintreeCards: {
    treatmentName: 'payment_show_braintree_credit_cards',
    show: 'show',
    fallback: 'show',
  },
  paymentShowManagement: {
    treatmentName: 'payment_show_management',
    show: 'show',
  },
  paymentBraintreeVaulted: {
    treatmentName: 'payment_show_braintree_vaulted_cards',
    show: 'show',
  },
  paymentBraintreeVaultedBillingAgreement: {
    treatmentName: 'payment_show_paypal_vaulted_billing_agreement',
    show: 'show',
  },
  paymentPageBasketItemLinks: {
    treatmentName: 'payment_page_basket_item_links',
    show: 'show',
  },
  onlineCancellationLink: {
    treatmentName: 'online_cancellation_link',
    show: 'show',
  },
  reorderCTANavigateToReviewAndPaySplit: {
    treatmentName: 'reorder_cta_navigate_to_review_and_pay',
    show: 'show',
  },
  studentDiscountShowInFooterMenu: {
    treatmentName: 'student_discount_show_in_footer_menu',
    show: 'show',
  },
  liveChatGenesys: {
    treatmentName: 'Live_Chat_Genesys',
    show: 'show',
  },
  productPageUpsell: {
    treatmentName: 'product_page_upsell',
    show: 'show',
  },
  showLoggedOutFreeShippingThreshold: {
    treatmentName: 'show_logged_out_free_shipping_threshold',
    show: 'show',
  },
  productPageSetQuantityToTwo: {
    treatmentName: 'product_page_set_quantity_to_two',
    show: 'show',
  },
  paymentPagePackSizeSwitcher: {
    treatmentName: 'payment_page_pack_size_switcher',
    show: 'show',
  },
  productPageShowPricePerLens: {
    treatmentName: 'product_page_show_price_per_lens',
    show: 'show',
  },
  basketPageEditPrescription: {
    treatmentName: 'basket_page_edit_prescription',
    show: 'show',
  },
  productPagePackSizeSwitcher: {
    treatmentName: 'product_page_pack_size_switcher',
    show: 'show',
  },
  paymentPageEditPrescription: {
    treatmentName: 'payment_page_edit_prescription',
    show: 'show',
  },
  orderHistoryTrackingStatus: {
    treatmentName: 'order_history_tracking_status',
    show: 'show',
  },
  orderSummaryBasketItemLinks: {
    treatmentName: 'order_summary_basket_item_links',
    show: 'show',
  },
}

export const authorImage = {
  path: 'misc/morgan-sayes.jpg',
}

// Set clearAction to true to remove the _a param from the URL on redirect
export const mapRedirects = {
  account: {
    redirect: 'account',
    clearAction: false,
  },
  cart: {
    redirect: 'basket',
    clearAction: false,
  },
  forgotPass: {
    redirect: 'forgot-password',
    clearAction: false,
  },
  login: {
    redirect: 'login',
    clearAction: true,
  },
  viewOrder: {
    redirect: 'orders',
    clearAction: false,
  },
  viewOrders: {
    redirect: 'orders',
    clearAction: false,
  },
}

export const visionExpressEyeTestUrl =
  'https://www.visionexpress.com/book-appointment?utm_source=lenstore&utm_medium=referral&utm_campaign=ls_bet'

export const headerContactLenses = {
  title: 'mainMenuItems.contactLenses.title',
  link: '/brand/317',
  seeAllLink: '/brand/317',
  trackingLabel: 'contactlenses',
  subMenus: [
    {
      title: 'mainMenuItems.contactLenses.type.title',
      items: [
        { title: 'mainMenuItems.contactLenses.type.daily', link: '/category/1' },
        { title: 'mainMenuItems.contactLenses.type.twoWeekly', link: '/category/2' },
        { title: 'mainMenuItems.contactLenses.type.monthly', link: '/category/3' },
        { title: 'mainMenuItems.contactLenses.type.toric', link: '/category/5' },
        { title: 'mainMenuItems.contactLenses.type.multifocal', link: '/category/6' },
        { title: 'mainMenuItems.contactLenses.type.coloured', link: '/brand/345' },
        { title: 'mainMenuItems.contactLenses.type.dailyToric', link: '/brand/123' },
        { title: 'mainMenuItems.contactLenses.type.cheapContactLenses', link: '/brand/348' },
      ],
    },
    {
      title: 'mainMenuItems.contactLenses.bestsellers.title',
      items: [
        { title: 'mainMenuItems.contactLenses.bestsellers.1DayAcuvueMoist', link: '/product/13' },
        { title: 'mainMenuItems.contactLenses.bestsellers.dailiesAquaComfortPlus', link: '/product/5' },
        { title: 'mainMenuItems.contactLenses.bestsellers.1DayAcuvueMoistForAstigmatism', link: '/product/106' },
        { title: 'mainMenuItems.contactLenses.bestsellers.acuvueOasys', link: '/product/38' },
        { title: 'mainMenuItems.contactLenses.bestsellers.acuvueOasys1Day', link: '/product/458' },
        { title: 'mainMenuItems.contactLenses.bestsellers.focusDailiesAllDayComfort', link: '/product/8' },
        { title: 'mainMenuItems.contactLenses.bestsellers.biofinity', link: '/product/63' },
        { title: 'mainMenuItems.contactLenses.bestsellers.clariti1Day', link: '/product/219' },
        { title: 'mainMenuItems.contactLenses.bestsellers.biofinityToric', link: '/product/87' },
        { title: 'mainMenuItems.contactLenses.bestsellers.1DayAcuvueMoistMultifocal', link: '/product/250' },
      ],
    },
    {
      title: 'mainMenuItems.contactLenses.brands.title',
      items: [
        { title: 'mainMenuItems.contactLenses.brands.acuvue', link: '/brand/1' },
        { title: 'mainMenuItems.contactLenses.brands.airOptix', link: '/brand/19' },
        { title: 'mainMenuItems.contactLenses.brands.biofinity', link: '/brand/27' },
        { title: 'mainMenuItems.contactLenses.brands.dailies', link: '/brand/62' },
        { title: 'mainMenuItems.contactLenses.brands.myDay', link: '/brand/310' },
        { title: 'mainMenuItems.contactLenses.brands.proclear', link: '/brand/75' },
        { title: 'mainMenuItems.contactLenses.brands.pureVision', link: '/brand/76' },
        { title: 'mainMenuItems.contactLenses.brands.specsaversEasyvision', link: '/document/111' },
        { title: 'mainMenuItems.contactLenses.brands.boots', link: '/document/110' },
      ],
    },
  ],
  spotlight: {
    heading: 'mainMenuItems.contactLenses.spotlight.heading',
    subheading: 'mainMenuItems.contactLenses.spotlight.subheading',
    image: '/jpg/menu/contact-lenses.jpg',
    colour: '#e8e2fb',
    button: { title: 'mainMenuItems.contactLenses.spotlight.cta', link: '/brand/317' },
  },
}

export const headerSolutionsEyecare = {
  title: 'mainMenuItems.solutionsEyecare.title',
  link: '/category/7',
  seeAllLink: '/category/7',
  trackingLabel: 'solutionsandeyecare',
  subMenus: [
    {
      title: 'mainMenuItems.solutionsEyecare.solutions.title',
      items: [
        { title: 'mainMenuItems.solutionsEyecare.solutions.multiPurpose', link: '/category/105' },
        { title: 'mainMenuItems.solutionsEyecare.solutions.saline', link: '/category/112' },
        { title: 'mainMenuItems.solutionsEyecare.solutions.hydrogenPeroxide', link: '/category/106' },
        { title: 'mainMenuItems.solutionsEyecare.solutions.hard', link: '/category/109' },
      ],
    },
    {
      title: 'mainMenuItems.solutionsEyecare.eyecare.title',
      items: [
        { title: 'mainMenuItems.solutionsEyecare.eyecare.cases', link: '/category/103' },
        { title: 'mainMenuItems.solutionsEyecare.eyecare.eyeDrops', link: '/category/104' },
        { title: 'mainMenuItems.solutionsEyecare.eyecare.dryEye', link: '/category/107' },
        { title: 'mainMenuItems.solutionsEyecare.eyecare.eyeVitamins', link: '/category/108' },
      ],
    },
    {
      title: 'mainMenuItems.solutionsEyecare.brands.title',
      items: [
        { title: 'mainMenuItems.solutionsEyecare.brands.renu', link: '/brand/315' },
        { title: 'mainMenuItems.solutionsEyecare.brands.biotrue', link: '/brand/173' },
        { title: 'mainMenuItems.solutionsEyecare.brands.hycosan', link: '/brand/280' },
        { title: 'mainMenuItems.solutionsEyecare.brands.blink', link: '/brand/134' },
        { title: 'mainMenuItems.solutionsEyecare.brands.systane', link: '/brand/170' },
        { title: 'mainMenuItems.solutionsEyecare.brands.optrex', link: '/brand/279' },
        { title: 'mainMenuItems.solutionsEyecare.brands.macuShield', link: '/brand/314' },
      ],
    },
  ],
  spotlight: {
    heading: 'mainMenuItems.solutionsEyecare.spotlight.heading',
    subheading: 'mainMenuItems.solutionsEyecare.spotlight.subheading',
    image: '/jpg/menu/accessories.jpg',
    colour: '#aeeae7',
    button: { title: 'mainMenuItems.solutionsEyecare.spotlight.cta', link: '/category/7' },
  },
}

export const headerHelp = {
  title: 'mainMenuItems.help.title',
  link: '/document/2',
  trackingLabel: 'help',
  subMenus: [
    {
      title: 'mainMenuItems.help.customerServices.title',
      items: [
        {
          title: 'mainMenuItems.help.customerServices.trackOrder',
          externalLink: 'https://www.lenstore.help/en-gb/how-can-i-track-my-order/',
          externalLinkDE: 'https://www.lenstore.help/de-de/wie-kann-ich-meine-sendungsverfolgung-einsehen/',
          externalLinkIT: 'https://www.lenstore.help/it-it/come-posso-tracciare-il-mio-ordine/',
          externalLinkFR: 'https://www.lenstore.help/fr-fr/comment-suivre-sa-commande/',
          externalLinkES: 'https://www.lenstore.help/es-es/localizacion-de-un-pedido/',
        },
        {
          title: 'mainMenuItems.help.customerServices.downloadInvoice',
          externalLink: 'https://www.lenstore.help/en-gb/how-can-i-find-my-invoice/',
          externalLinkDE: 'https://www.lenstore.help/de-de/wie-kann-ich-meine-rechnung-herunterladen/',
          externalLinkIT: 'https://www.lenstore.help/it-it/come-posso-trovare-la-mia-fattura/',
          externalLinkFR: 'https://www.lenstore.help/fr-fr/comment-puis-je-trouver-ma-facture/',
          externalLinkES: 'https://www.lenstore.help/es-es/como-puedo-encontrar-mi-factura/',
        },
        { title: 'mainMenuItems.help.customerServices.delivery', link: '/document/10' },
        { title: 'mainMenuItems.help.customerServices.returns', link: '/document/11' },
        {
          title: 'mainMenuItems.help.customerServices.helpCentre',
          externalLink: 'https://www.lenstore.help/en-gb',
          externalLinkDE: 'https://www.lenstore.help/de-de',
          externalLinkIT: 'https://www.lenstore.help/it-it',
          externalLinkFR: 'https://www.lenstore.help/fr-fr',
          externalLinkES: 'https://www.lenstore.help/es-es',
        },
        { title: 'mainMenuItems.help.customerServices.contact', link: '/document/2' },
      ],
    },
    {
      title: 'mainMenuItems.help.lensInformation.title',
      items: [
        {
          title: 'mainMenuItems.help.lensInformation.readPrescription',
          externalLink: 'https://www.lenstore.co.uk/eyecare/how-to-read-your-contact-lens-prescription',
          externalLinkDE: 'https://www.lenstore.de/augenpflege/kontaktlinsenwerte-wie-gebe-ich-sie-richtig-ein/',
          externalLinkIT:
            'https://www.lenstore.it/cura-degli-occhi/come-leggere-e-inserire-i-parametri-della-prescrizione-per-le-tue-lenti-a-contatto',
          externalLinkFR: 'https://www.lenstore.fr/soins-des-yeux/comment-lire-une-ordonnance-de-lentilles-de-contact/',
          externalLinkES: '',
        },
        {
          title: 'mainMenuItems.help.lensInformation.putInLenses',
          externalLink: 'https://www.lenstore.co.uk/eyecare/how-to-put-in-take-out-contact-lenses',
          externalLinkDE:
            'https://www.lenstore.de/augenpflege/step-by-step-das-einsetzen-und-entfernen-von-kontaktlinsen-leicht-gemacht/',
          externalLinkIT:
            'https://www.lenstore.it/cura-degli-occhi/guida-pratica-per-inserire-e-rimuovere-le-lenti-a-contatto-nel-modo-corretto/',
          externalLinkFR:
            'https://www.lenstore.fr/soins-des-yeux/comment-mettre-et-enlever-vos-lentilles-de-contact%e2%80%af/',
          externalLinkES: '',
        },
        {
          title: 'mainMenuItems.help.lensInformation.insideOut',
          externalLink: 'https://www.lenstore.co.uk/eyecare/how-check-if-your-contact-lenses-are-inside-out',
          externalLinkDE: 'https://www.lenstore.de/augenpflege/wie-erkenne-ich-dass-eine-linse-richtig-aufliegt/',
          externalLinkIT:
            'https://www.lenstore.it/cura-degli-occhi/come-capire-se-le-lenti-a-contatto-sono-al-rovescio/',
          externalLinkFR: 'https://www.lenstore.fr/soins-des-yeux/ma-lentille-est-elle-a-lendroit-ou-a-lenvers/',
          externalLinkES: 'https://www.lenstore.es/cuidado-de-ojos/como-saber-si-lleva-las-lentillas-del-reves/',
        },
        {
          title: 'mainMenuItems.help.lensInformation.learnMore',
          externalLink: 'https://www.lenstore.co.uk/eyecare/all-about-contact-lenses',
          externalLinkDE: 'https://www.lenstore.de/augenpflege/category/linsenguide/',
          externalLinkIT: 'https://www.lenstore.it/cura-degli-occhi/category/guida-alle-lenti/',
          externalLinkFR: 'https://www.lenstore.fr/soins-des-yeux/category/guide-des-lentilles/',
          externalLinkES: 'https://www.lenstore.es/cuidado-de-ojos/category/guia-para-lentillas/',
        },
      ],
    },
    {
      title: 'mainMenuItems.help.eyeHealth.title',
      items: [
        {
          title: 'mainMenuItems.help.eyeHealth.myopia',
          externalLink: 'https://www.lenstore.co.uk/eyecare/myopia',
          externalLinkDE: '',
          externalLinkIT: 'https://www.lenstore.it/cura-degli-occhi/miopia-cause-sintomi-e-cura/',
          externalLinkFR: 'https://www.lenstore.fr/soins-des-yeux/myopie-causes-symptomes-et-traitement/',
          externalLinkES: 'https://www.lenstore.es/cuidado-de-ojos/miopia-causas-sintomas-y-tratamiento/',
        },
        {
          title: 'mainMenuItems.help.eyeHealth.hyperopia',
          externalLink: 'https://www.lenstore.co.uk/eyecare/what-is-hyperopia',
          externalLinkDE: '',
          externalLinkIT: 'https://www.lenstore.it/cura-degli-occhi/che-cose-lipermetropia/',
          externalLinkFR: 'https://www.lenstore.fr/soins-des-yeux/quest-ce-que-lhypermetropie/',
          externalLinkES: 'https://www.lenstore.es/cuidado-de-ojos/que-es-la-hipermetropia/',
        },
        {
          title: 'mainMenuItems.help.eyeHealth.astigmatism',
          externalLink: 'https://www.lenstore.co.uk/eyecare/astigmatism',
          externalLinkDE: 'https://www.lenstore.de/augenpflege/astigmatismus-ursachen-symptome-korrektur/',
          externalLinkIT: 'https://www.lenstore.it/cura-degli-occhi/astigmatismo-la-guida-completa/',
          externalLinkFR:
            'https://www.lenstore.fr/soins-des-yeux/lastigmatisme--quels-sont-ses-signes-et-ses-symptomes',
          externalLinkES: 'https://www.lenstore.es/cuidado-de-ojos/la-guia-definitiva-para-el-astigmatismo/',
        },
        {
          title: 'mainMenuItems.help.eyeHealth.presbyopia',
          externalLink: 'https://www.lenstore.co.uk/eyecare/your-guide-presbyopia',
          externalLinkDE: '',
          externalLinkIT: 'https://www.lenstore.it/cura-degli-occhi/tutto-quello-che-ce-da-sapere-sulla-presbiopia/',
          externalLinkFR: 'https://www.lenstore.fr/soins-des-yeux/presbytie-lentilles-de-contact-ou-lunettes/',
          externalLinkES: 'https://www.lenstore.es/cuidado-de-ojos/todo-lo-que-necesita-saber-sobre-la-presbicia/',
        },
        {
          title: 'mainMenuItems.help.eyeHealth.dryEye',
          externalLink: 'https://www.lenstore.co.uk/eyecare/dry-eye-syndrome',
          externalLinkDE: 'https://www.lenstore.de/augenpflege/diagnose-trockene-augen/',
          externalLinkIT: 'https://www.lenstore.it/cura-degli-occhi/sindrome-dellocchio-secco/',
          externalLinkFR:
            'https://www.lenstore.fr/soins-des-yeux/secheresse-oculaire-ou-syndrome-de-loeil-sec%e2%80%af-quels-sont-les-causes-les-symptomes-et-les-moyens-de-prevention%e2%80%af/',
          externalLinkES: 'https://www.lenstore.es/cuidado-de-ojos/como-combatir-los-ojos-secos-este-invierno/',
        },
        {
          title: 'mainMenuItems.help.eyeHealth.conjunctivitis',
          externalLink: 'https://www.lenstore.co.uk/eyecare/your-guide-conjunctivitis',
          externalLinkDE: 'https://www.lenstore.de/augenpflege/bindehautentzuendung-symptome-ursachen-und-behandlung/',
          externalLinkIT: 'https://www.lenstore.it/cura-degli-occhi/congiuntivite-sintomi-cure/',
          externalLinkFR: 'https://www.lenstore.fr/soins-des-yeux/tout-ce-que-vous-devez-savoir-sur-la-conjonctivite/',
          externalLinkES: 'https://www.lenstore.es/cuidado-de-ojos/todo-lo-que-necesita-saber-sobre-la-conjuntivitis/',
        },
        {
          title: 'mainMenuItems.help.eyeHealth.learnMore',
          externalLink: 'https://www.lenstore.co.uk/eyecare/eye-health',
          externalLinkDE: 'https://www.lenstore.de/augenpflege/category/health/',
          externalLinkIT: 'https://www.lenstore.it/cura-degli-occhi/category/salute-degli-occhi/',
          externalLinkFR: 'https://www.lenstore.fr/soins-des-yeux/category/sante/',
          externalLinkES: 'https://www.lenstore.es/cuidado-de-ojos/category/salud-ocular/',
        },
      ],
    },
  ],
  spotlight: {
    heading: 'mainMenuItems.help.spotlight.heading',
    subheading: 'mainMenuItems.help.spotlight.subheading',
    image: '/jpg/menu/help.jpg',
    colour: '#e8e2fb',
    button: {
      title: 'mainMenuItems.help.spotlight.cta',
      externalLink: 'https://www.lenstore.help/en-gb',
      externalLinkDE: 'https://www.lenstore.help/de-de',
      externalLinkIT: 'https://www.lenstore.help/it-it',
      externalLinkFR: 'https://www.lenstore.help/fr-fr',
      externalLinkES: 'https://www.lenstore.help/es-es',
    },
  },
}

export const hrefLang = [
  {
    rel: 'alternate',
    hreflang: 'en',
    href: 'https://www.lenstore.co.uk/',
  },
  {
    rel: 'alternate',
    hreflang: 'de',
    href: 'https://www.lenstore.de/',
  },
  {
    rel: 'alternate',
    hreflang: 'it',
    href: 'https://www.lenstore.it/',
  },
  {
    rel: 'alternate',
    hreflang: 'fr',
    href: 'https://www.lenstore.fr/',
  },
  {
    rel: 'alternate',
    hreflang: 'es',
    href: 'https://www.lenstore.es/',
  },
]

export const localeIT = {
  name: 'it',
  weekdays: 'domenica_lunedì_martedì_mercoledì_giovedì_venerdì_sabato'.split('_'),
  weekdaysShort: 'dom_lun_mar_mer_gio_ven_sab'.split('_'),
  weekStart: 1,
  months: 'gennaio_febbraio_marzo_aprile_maggio_giugno_luglio_agosto_settembre_ottobre_novembre_dicembre'.split('_'),
  monthsShort: 'gen_feb_mar_apr_mag_giu_lug_ago_set_ott_nov_dic'.split('_'),
  formats: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd D MMMM YYYY HH:mm',
  },
  ordinal: (n) => `${n}º`,
  buttonCancel: 'Annulla',
  buttonValidate: 'Ok',
  rangeHeaderText: 'Dalle %d Alle 13',
}

export const listPageBannerData = {
  categories: [1, 2, 3, 4, 5, 6, 7],
  brands: [1, 348, 345, 317],
}

export const orderStatuses = {
  AWAITING_PAYMENT: 1,
  BEING_FULFILLED: 2,
  DISPATCHED: 3,
  PAYMENT_DECLINED: 4,
  FAILED_FRAUD_REVIEW: 5,
  CANCELLED: 6,
  READY_TO_DISPATCH: 7,
  PRESCRIPTION_BEING_VERIFIED: 8,
  PRESCRIPTION_VERIFICATION_FAILED: 9,
  READY_FOR_COLLECTION: 10,
}

export const productTypes = {
  CONTACT_LENS: 'Contact lens',
  ACCESSORY: 'Accessory',
  SUN_GLASSES: 'Sunglasses',
}

export const threeDSContextValues = {
  CANCELLED: 'cancelled',
  NOT_SHOWN: 'not_shown',
}

export const transactionErrorMappings = [
  {
    code: 'P02',
    type: 'paymentCompleted',
  },
  {
    code: 'P03',
    type: 'paymentOngoing',
  },
]

export const psTransactionErrorMappings = [
  {
    code: 'PS-034',
    type: 'insufficientFunds',
  },
  {
    code: 'PS-054',
    type: 'insufficientFunds',
  },
  {
    code: 'PS-014',
    type: 'insufficientFunds',
  },
  {
    code: 'PS-026',
    type: 'bankWontProcess',
  },
  {
    code: 'PS-038',
    type: 'cardNumberIssue',
  },
  {
    code: 'PS-056',
    type: 'cardNumberIssue',
  },
  {
    code: 'PS-015',
    type: 'cardNumberIssue',
  },
  {
    code: 'PS-059',
    type: 'cardNumberIssue',
  },
  {
    code: 'PS-041',
    type: 'expirationDateError',
  },
  {
    code: 'PS-001',
    type: 'generic',
  },
  {
    code: 'PS-030',
    type: 'outdatedCard',
  },
  {
    code: 'PS-016',
    type: 'cvvMismatch',
  },
  {
    code: 'PS-019',
    type: 'unableToCompleteWithCard',
  },
  {
    code: 'PS-010',
    type: 'unableToCompleteWithCard',
  },
  {
    code: 'PS-092',
    type: 'bankRequiresCustomerAction',
  },
  {
    code: 'PS-093',
    type: 'bankRequiresCustomerAction',
  },
  {
    code: 'PS-091',
    type: 'bankRequiresCustomerAction',
  },
  {
    code: 'PS-106',
    type: 'bankRequiresCustomerAction',
  },
  {
    code: 'PS-013',
    type: 'bankRequiresCustomerAction',
  },
  {
    code: 'PS-012',
    type: 'bankRequiresCustomerAction',
  },
  {
    code: 'PS-049',
    type: 'bankRequiresCustomerAction',
  },
  {
    code: 'PS-108',
    type: 'bankRequiresCustomerAction',
  },
  {
    code: 'PS-098',
    type: 'bankRequiresCustomerAction',
  },
  {
    code: 'PS-024',
    type: 'bankRequiresCustomerAction',
  },
  {
    code: 'PS-044',
    type: 'bankRequiresCustomerAction',
  },
  {
    code: 'PS-017',
    type: 'bankRequiresCustomerAction',
  },
  {
    code: 'PS-025',
    type: 'updatedCardNeeded',
  },
  {
    code: 'PS-094',
    type: 'unableToProcessCard',
  },
  {
    code: 'PS-018',
    type: 'unableToProcessCard',
  },
  {
    code: 'PS-102',
    type: 'unableToProcessCard',
  },
  {
    code: 'PS-043',
    type: 'unableToProcessCard',
  },
  {
    code: 'PS-099',
    type: 'unableToProcessCard',
  },
  {
    code: 'PS-036',
    type: 'invalidAuthCode',
  },
]

export const passwordPolicy = {
  minimumValidStrength: 4,
  minimumCharacterLength: 8,
  forbiddenWordsUniversal: ['lenstore'],
  forbiddenWordsLocale: {
    'en-GB': ['contact lens', 'contact lenses'],
    'de-DE': ['kontaktlinse', 'kontaktlinsen'],
    'it-IT': ['lenti a contatto', 'lente a contatto'],
    'fr-FR': ['lentille de contact', 'lentilles de contact'],
    'es-ES': ['lente de contacto', 'lentes de contacto'],
  },
  // Minimum 8 characters, max 3 repeated or sequential letters or numbers
  pattern:
    '^(?!.*([a-zA-Z])\\1{3})(?!.*(d)\\2{3})(?!.*(?:abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz|ABCD|BCDE|CDEF|DEFG|EFGH|FGHI|GHIJ|HIJK|IJKL|JKLM|KLMN|LMNO|MNOP|NOPQ|OPQR|PQRS|QRST|RSTU|STUV|TUVW|UVWX|VWXY|WXYZ|1234|2345|3456|4567|5678|6789|7890|8901|9012|0123)).{8,}$',
}

export function cdnPath(value, cdnPath, width) {
  return `${cdnPath}/${width}/${value}`
}

export function cdnPathWebP(value, cdnPath, width) {
  value = value.replace('jpg', 'webp').replace('jpeg', 'webp').replace('png', 'webp')
  return this.cdnPath(value, cdnPath, width)
}
